import React from "react"
import { graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import SEO from "../components/seo"
import Layout from "../components/layout"
import HeroImage from "../components/heroImage"
import GoTo from "../components/goTo"

export default function ProfessionalsPage({ data }) {
  const intl = useIntl()
  return (
    <React.Fragment>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: "professionals.title" })}
        description={intl.formatMessage({ id: "professionals.caption" })}
      />
      <Layout intl={intl}>
        <HeroImage
          image={data.heroImage}
          title={intl.formatMessage({ id: "professionals.title" })}
          caption={intl.formatMessage({ id: "professionals.caption" })}
        />

        <section className="container mx-auto px-5 my-20">
          <div className="-mx-5 flex flex-wrap">
            <div className="px-5 w-1/2 md:w-1/3 mt-16 ">
              <img
                className="rounded-full w-40 h-40 border-2 border-green-400 mx-auto"
                src="https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Indonesia.svg"
                alt="Bahasa Indonesia"
              />
              <h2 className="text-center text-4xl font-light text-green-600">
                {intl.formatMessage({ id: "professionals.first_expert" })}
              </h2>
              <p className="text-center font-light">
                {intl.formatMessage({ id: "professionals.first_expert_title" })}
              </p>
            </div>
            <div className="px-5 w-1/2 md:w-1/3 mt-16 ">
              <img
                className="rounded-full w-40 h-40 border-2 border-green-400 mx-auto"
                src="https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Indonesia.svg"
                alt="Bahasa Indonesia"
              />
              <h2 className="text-center text-4xl font-light text-green-600">
                {intl.formatMessage({ id: "professionals.second_expert" })}
              </h2>
              <p className="text-center font-light">
                {intl.formatMessage({
                  id: "professionals.second_expert_title",
                })}
              </p>
            </div>
            <div className="px-5 w-1/2 md:w-1/3 mt-16 ">
              <img
                className="rounded-full w-40 h-40 border-2 border-green-400 mx-auto"
                src="https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Indonesia.svg"
                alt="Bahasa Indonesia"
              />
              <h2 className="text-center text-4xl font-light text-green-600">
                {intl.formatMessage({ id: "professionals.third_expert" })}
              </h2>
              <p className="text-center font-light">
                {intl.formatMessage({ id: "professionals.third_expert_title" })}
              </p>
            </div>
            <div className="px-5 w-1/2 md:w-1/3 mt-16 ">
              <img
                className="rounded-full w-40 h-40 border-2 border-green-400 mx-auto"
                src="https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Indonesia.svg"
                alt="Bahasa Indonesia"
              />
              <h2 className="text-center text-4xl font-light text-green-600">
                {intl.formatMessage({ id: "professionals.fourth_expert" })}
              </h2>
              <p className="text-center font-light">
                {intl.formatMessage({
                  id: "professionals.fourth_expert_title",
                })}
              </p>
            </div>
            <div className="px-5 w-1/2 md:w-1/3 mt-16 ">
              <img
                className="rounded-full w-40 h-40 border-2 border-green-400 mx-auto"
                src="https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Indonesia.svg"
                alt="Bahasa Indonesia"
              />
              <h2 className="text-center text-4xl font-light text-green-600">
                {intl.formatMessage({ id: "professionals.fifth_expert" })}
              </h2>
              <p className="text-center font-light">
                {intl.formatMessage({ id: "professionals.fifth_expert_title" })}
              </p>
            </div>
          </div>
        </section>

        <section className="flex flex-wrap">
          <div className="w-full md:w-1/2">
            <GoTo
              image={data.heroImage}
              page="/activities/"
              title={intl.formatMessage({ id: "activities.title" })}
              caption={intl.formatMessage({ id: "activities.caption" })}
              button={intl.formatMessage({ id: "activities.button" })}
            />
          </div>
          <div className="w-full md:w-1/2">
            <GoTo
              image={data.expertise}
              page="/expertise/"
              title={intl.formatMessage({ id: "expertise.title" })}
              caption={intl.formatMessage({ id: "expertise.caption" })}
              button={intl.formatMessage({ id: "expertise.button" })}
            />
          </div>
        </section>
      </Layout>
    </React.Fragment>
  )
}

export const query = graphql`
  {
    heroImage: file(relativePath: { eq: "about/tentang-yayasan.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    expertise: file(relativePath: { eq: "expertise/kepakaran.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
